$primary-color: #417505;
$max-page-size: 480px;

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

body {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}

.visible-on-hover {
  opacity: 0;
  transition: all 0.5s;
  &:hover {
    opacity: 1;
  }
}

body {
  height: 100vh;
  font-family: Inter,-apple-system,BlinkMacSystemFont,PingFang SC,Hiragino Sans GB,noto sans,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}

.arco-page-header {
  padding: 10px 0 16px 0;
}

.arco-page-header-head-wrapper {
  padding-left: 24px;
  padding-right: 20px;
}

.arco-page-header-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 28px;
}

.arco-page-header-head-main {
  display: flex;
  align-items: center;
  min-height: 30px;
  overflow: hidden;
}

.arco-page-header-head-main-with-back {
  margin-left: -8px;
  padding-left: 8px;
}

.arco-page-header-back {
  color: rgb(78,89,105);
  font-size: 14px;
  margin-right: 12px;
  width: 18%;
}

.arco-icon-hover {
  cursor: pointer;
}

.arco-page-header-back-icon {
  position: relative;
}

.arco-icon-hover .arco-icon {
  position: relative;
  vertical-align: -0.09em;
}

.arco-icon[stroke=currentColor] {
  stroke: currentColor;
  fill: none;
}

.arco-page-header-title {
  overflow: hidden;
  white-space: nowrap;
  color: rgb(29,33,41);
  font-size: 20px;
  font-weight: 600;
  width: 76px;
}

.arco-page-header-head-extra {
  white-space: nowrap;
  overflow: hidden;
}

.arco-btn-secondary:not(.arco-btn-disabled) {
  background-color: rgb(242,243,245);
  color: rgb(78,89,105);
  border: 1px solid transparent;
}

.arco-btn-size-default {
  padding: 0 15px;
  font-size: 14px;
  height: 32px;
  border-radius: 2px;
}

.arco-btn {
  display: inline-block;
  position: relative;
  outline: none;
  font-weight: 400;
  appearance: none;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  transition: all .1s cubic-bezier(0,0,1,1);
  box-sizing: border-box;
  line-height: 1.5715;
  margin-right: 20px;
}

button, select {
  text-transform: none;
}

button, input {
  overflow: visible;
}

.arco-card-size-default {
  font-size: 14px;
}

.arco-card-bordered {
  border: 1px solid rgb(229,230,235);
  border-radius: 2px;
}

.arco-card {
  position: relative;
  background: #fff;
  transition: box-shadow .2s cubic-bezier(0,0,1,1);
  border-radius: var(--border-radius-none);
}

.arco-layout {
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  flex-direction: column;
}

.arco-layout-has-sider {
  flex-direction: row;
}

.arco-tabs-header-scroll {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
}

.arco-tabs-header-wrapper {
  display: flex;
  overflow: hidden;
  flex: 1;
}

._largeTabsHeader_d2sio_1 .arco-tabs-header {
  width: 100%;
  display: flex;
}

.arco-tabs-header {
  position: relative;
  display: inline-block;
  transition: transform .2s cubic-bezier(.34,.69,.1,1);
  white-space: nowrap;
}

._largeTabsHeader_d2sio_1 .arco-tabs-header .arco-tabs-header-title {
  flex: 1;
  margin: 0!important;
  padding: 0!important;
  height: 60px;
  line-height: 60px;
}

.arco-tabs-header-title-active, .arco-tabs-header-title-active:hover {
  color: rgb(52,145,250);
  font-weight: 500;
}

.arco-tabs-header-ink {
  position: absolute;
  bottom: 0;
  right: initial;
  top: initial;
  height: 2px;
  background-color: rgb(52,145,250);
  transition: left .2s cubic-bezier(.34,.69,.1,1),width .2s cubic-bezier(.34,.69,.1,1);
  width: 180px !important;
}

.arco-collapse {
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid rgb(229,230,235);
  line-height: 1.5715;
}

.arco-collapse-item {
  border-bottom: 1px solid rgb(229,230,235);
  box-sizing: border-box;
}

.arco-collapse-item-active>.arco-collapse-item-header {
  background-color: rgb(247,248,250);
  border-color: rgb(229,230,235);
  transition: border-color 0s ease 0s;
}

.arco-collapse-item .arco-collapse-item-icon-hover {
  transform: translateY(-50%);
  text-align: center;
  width: 4% !important;
}

.arco-icon-hover {
  position: relative;
  display: inline-block;
  cursor: pointer;
  line-height: 0;
}

.arco-collapse-item-header-icon-down {
  transform: rotate(90deg);
}

.arco-collapse-item-header-icon {
  color: rgb(107,119,133);
  font-size: 14px;
  transition: transform .2s cubic-bezier(.34,.69,.1,1);
  vertical-align: middle;
  position: relative;
  display: block;
}

.arco-collapse-item-active>.arco-collapse-item-header .arco-collapse-item-header-title {
  font-weight: 500;
}

.arco-collapse-item-header-title {
  // display: inline;
  width: 100%;
  margin-left: 7px;
}

.arco-collapse-item-header-left {
  padding-left: 10px;
  padding-right: 13px;
}

.arco-collapse-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: var(--color-neutral-1);
  border-bottom: 1px solid transparent;
  color: var(--color-text-1);
  cursor: pointer;
  font-size: 14px;
  overflow: hidden;
  line-height: 24px;
  transition: border-color 0s ease .19s;
}

.arco-tabs-header-title-text > div {
  line-height: 60px !important;
}

.arco-collapse-item-content-expanded {
  display: block;
  height: auto;
}

.arco-collapse-item-content {
  display: none;
  overflow: hidden;
  position: relative;
  color: rgb(29,33,41);
  background-color: #fff;
  font-size: 14px;
  transition: height .2s cubic-bezier(.34,.69,.1,1);
}

.arco-row-justify-start {
  justify-content: flex-start;
}

.arco-row-align-start {
  align-items: flex-start;
}

.arco-row {
  display: flex;
  flex-flow: row wrap;
}

._blockItem_1ajtj_6:nth-child(3n+1) {
  margin-left: 15px;
}

._blockItem_1ajtj_6 {
  margin-bottom: 20px;
  box-shadow: #373f470a 0 0 0 1px, #373f4714 0 4px 4px, #373f4714 0 8px 24px;
  border: 2px solid transparent;
  border-radius: 4px;
  margin-right: 15px;
}

._blockItem_1ajtj_6:hover {
  box-shadow: 0 0 2px 2px rgb(var(--primary-4, #1890ff))!important;
}

.arco-collapse-item-content-box {
  padding: 8px 13px 8px 34px;
}

.arco-space-vertical {
  flex-direction: column;
}

.arco-space {
  display: inline-flex;
}

.arco-col-7 {
  width: 29.16666667%;
  flex: 0 0 29.16666667%;
}

.arco-col {
  position: relative;
  box-sizing: border-box;
}

.arco-form-label-item {
  text-align: right;
  white-space: nowrap;
  line-height: 32px;
}

.arco-form-label-item-left {
  text-align: left;
}

.arco-col-offset-1 {
  margin-left: 4.16666667%;
}

.arco-col-16 {
  width: 66.66666667%;
  flex: 0 0 66.66666667%;
}

.arco-form-item-control-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.arco-form-item-control {
  display: flex;
  align-items: center;
  min-height: 32px;
  width: 100%;
}

.arco-form-item-control-children {
  position: relative;
}

.arco-form-item-control-children {
  width: 100%;
  flex: 1;
}

.arco-input {
  line-height: 1.5715;
  outline: none;
  appearance: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  width: 100%;
  border-radius: var(--border-radius-small);
  color: var(--color-text-1);
  padding: 4px 12px;
  font-size: 14px;
  box-sizing: border-box;
  transition: color .1s cubic-bezier(0,0,1,1),border-color .1s cubic-bezier(0,0,1,1),background-color .1s cubic-bezier(0,0,1,1);
  border: 1px solid var(--color-neutral-3);
  background-color: transparent;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  line-height: 1.15;
  margin: 0;
}

.arco-form-message {
  font-size: 12px;
  color: rgb(var(--danger-6));
  min-height: 20px;
  line-height: 20px;
}

.arco-form-message-help {
  color: var(--color-text-3);
}

.formblink-enter-done {
  animation: arco-form-blink .5s cubic-bezier(0,0,1,1);
}

.arco-col-11 {
  width: 45.83333333%;
  flex: 0 0 45.83333333%;
}

.arco-form-item {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.arco-col-24 {
  width: 100%;
  flex: 0 0 100%;
}

textarea {
  overflow: auto;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  line-height: 1.15;
  margin: 0;
}

textarea {
  overflow: auto;
}

.arco-textarea {
  outline: none;
  appearance: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  width: 100%;
  border-radius: var(--border-radius-small);
  color: var(--color-text-1);
  box-sizing: border-box;
  transition: color .1s cubic-bezier(0,0,1,1),border-color .1s cubic-bezier(0,0,1,1),background-color .1s cubic-bezier(0,0,1,1);
  border: 1px solid var(--color-neutral-3);
  background-color: transparent;
  font-size: 14px;
  vertical-align: top;
  position: relative;
  padding: 4px 12px;
  max-width: 100%;
  min-height: 32px;
  height: auto;
  line-height: 1.5715;
  resize: vertical;
  overflow: auto;
}

body {
  --red-1: 255,236,232;
  --red-2: 253,205,197;
  --red-3: 251,172,163;
  --red-4: 249,137,129;
  --red-5: 247,101,96;
  --red-6: 245,63,63;
  --red-7: 203,39,45;
  --red-8: 161,21,30;
  --red-9: 119,8,19;
  --red-10: 77,0,10;
  --orangered-1: 255,243,232;
  --orangered-2: 253,221,195;
  --orangered-3: 252,197,159;
  --orangered-4: 250,172,123;
  --orangered-5: 249,144,87;
  --orangered-6: 247,114,52;
  --orangered-7: 204,81,32;
  --orangered-8: 162,53,17;
  --orangered-9: 119,31,6;
  --orangered-10: 77,14,0;
  --orange-1: 255,247,232;
  --orange-2: 255,228,186;
  --orange-3: 255,207,139;
  --orange-4: 255,182,93;
  --orange-5: 255,154,46;
  --orange-6: 255,125,0;
  --orange-7: 210,95,0;
  --orange-8: 166,69,0;
  --orange-9: 121,46,0;
  --orange-10: 77,27,0;
  --gold-1: 255,252,232;
  --gold-2: 253,244,191;
  --gold-3: 252,233,150;
  --gold-4: 250,220,109;
  --gold-5: 249,204,69;
  --gold-6: 247,186,30;
  --gold-7: 204,146,19;
  --gold-8: 162,109,10;
  --gold-9: 119,75,4;
  --gold-10: 77,45,0;
  --yellow-1: 254,255,232;
  --yellow-2: 254,254,190;
  --yellow-3: 253,250,148;
  --yellow-4: 252,242,107;
  --yellow-5: 251,232,66;
  --yellow-6: 250,220,25;
  --yellow-7: 207,175,15;
  --yellow-8: 163,132,8;
  --yellow-9: 120,93,3;
  --yellow-10: 77,56,0;
  --lime-1: 252,255,232;
  --lime-2: 237,248,187;
  --lime-3: 220,241,144;
  --lime-4: 201,233,104;
  --lime-5: 181,226,65;
  --lime-6: 159,219,29;
  --lime-7: 126,183,18;
  --lime-8: 95,148,10;
  --lime-9: 67,112,4;
  --lime-10: 42,77,0;
  --green-1: 232,255,234;
  --green-2: 175,240,181;
  --green-3: 123,225,136;
  --green-4: 76,210,99;
  --green-5: 35,195,67;
  --green-6: 0,180,42;
  --green-7: 0,154,41;
  --green-8: 0,128,38;
  --green-9: 0,102,34;
  --green-10: 0,77,28;
  --cyan-1: 232,255,251;
  --cyan-2: 183,244,236;
  --cyan-3: 137,233,224;
  --cyan-4: 94,223,214;
  --cyan-5: 55,212,207;
  --cyan-6: 20,201,201;
  --cyan-7: 13,165,170;
  --cyan-8: 7,130,139;
  --cyan-9: 3,97,108;
  --cyan-10: 0,66,77;
  --blue-1: 232,247,255;
  --blue-2: 195,231,254;
  --blue-3: 159,212,253;
  --blue-4: 123,192,252;
  --blue-5: 87,169,251;
  --blue-6: 52,145,250;
  --blue-7: 32,108,207;
  --blue-8: 17,75,163;
  --blue-9: 6,48,120;
  --blue-10: 0,26,77;
  --arcoblue-1: 232,243,255;
  --arcoblue-2: 190,218,255;
  --arcoblue-3: 148,191,255;
  --arcoblue-4: 106,161,255;
  --arcoblue-5: 64,128,255;
  --arcoblue-6: 22,93,255;
  --arcoblue-7: 14,66,210;
  --arcoblue-8: 7,44,166;
  --arcoblue-9: 3,26,121;
  --arcoblue-10: 0,13,77;
  --purple-1: 245,232,255;
  --purple-2: 221,190,246;
  --purple-3: 195,150,237;
  --purple-4: 168,113,227;
  --purple-5: 141,78,218;
  --purple-6: 114,46,209;
  --purple-7: 85,29,176;
  --purple-8: 60,16,143;
  --purple-9: 39,6,110;
  --purple-10: 22,0,77;
  --pinkpurple-1: 255,232,251;
  --pinkpurple-2: 247,186,239;
  --pinkpurple-3: 240,142,230;
  --pinkpurple-4: 232,101,223;
  --pinkpurple-5: 225,62,219;
  --pinkpurple-6: 217,26,217;
  --pinkpurple-7: 176,16,182;
  --pinkpurple-8: 138,9,147;
  --pinkpurple-9: 101,3,112;
  --pinkpurple-10: 66,0,77;
  --magenta-1: 255,232,241;
  --magenta-2: 253,194,219;
  --magenta-3: 251,157,199;
  --magenta-4: 249,121,183;
  --magenta-5: 247,84,168;
  --magenta-6: 245,49,157;
  --magenta-7: 203,30,131;
  --magenta-8: 161,16,105;
  --magenta-9: 119,6,79;
  --magenta-10: 77,0,52;
  --gray-1: 247,248,250;
  --gray-2: 242,243,245;
  --gray-3: 229,230,235;
  --gray-4: 201,205,212;
  --gray-5: 169,174,184;
  --gray-6: 134,144,156;
  --gray-7: 107,119,133;
  --gray-8: 78,89,105;
  --gray-9: 39,46,59;
  --gray-10: 29,33,41;
  --primary-1: 232,247,255;
  --primary-2: 195,231,254;
  --primary-3: 159,212,253;
  --primary-4: 123,192,252;
  --primary-5: 87,169,251;
  --primary-6: 52,145,250;
  --primary-7: 32,108,207;
  --primary-8: 17,75,163;
  --primary-9: 6,48,120;
  --primary-10: 0,26,77;
  --link-1: var(--arcoblue-1);
  --link-2: var(--arcoblue-2);
  --link-3: var(--arcoblue-3);
  --link-4: var(--arcoblue-4);
  --link-5: var(--arcoblue-5);
  --link-6: var(--arcoblue-6);
  --link-7: var(--arcoblue-7);
  --link-8: var(--arcoblue-8);
  --link-9: var(--arcoblue-9);
  --link-10: var(--arcoblue-10);
  --success-1: var(--green-1);
  --success-2: var(--green-2);
  --success-3: var(--green-3);
  --success-4: var(--green-4);
  --success-5: var(--green-5);
  --success-6: var(--green-6);
  --success-7: var(--green-7);
  --success-8: var(--green-8);
  --success-9: var(--green-9);
  --success-10: var(--green-10);
  --danger-1: var(--red-1);
  --danger-2: var(--red-2);
  --danger-3: var(--red-3);
  --danger-4: var(--red-4);
  --danger-5: var(--red-5);
  --danger-6: var(--red-6);
  --danger-7: var(--red-7);
  --danger-8: var(--red-8);
  --danger-9: var(--red-9);
  --danger-10: var(--red-10);
  --warning-1: var(--orange-1);
  --warning-2: var(--orange-2);
  --warning-3: var(--orange-3);
  --warning-4: var(--orange-4);
  --warning-5: var(--orange-5);
  --warning-6: var(--orange-6);
  --warning-7: var(--orange-7);
  --warning-8: var(--orange-8);
  --warning-9: var(--orange-9);
  --warning-10: var(--orange-10);

  --color-white: #ffffff;
  --color-black: #000000;
  --color-border: rgb(var(--gray-3));
  --color-bg-popup: var(--color-bg-5);
  --color-bg-1: #fff;
  --color-bg-2: #fff;
  --color-bg-3: #fff;
  --color-bg-4: #fff;
  --color-bg-5: #fff;
  --color-bg-white: #fff;
  --color-neutral-1: rgb(var(--gray-1));
  --color-neutral-2: rgb(var(--gray-2));
  --color-neutral-3: rgb(var(--gray-3));
  --color-neutral-4: rgb(var(--gray-4));
  --color-neutral-5: rgb(var(--gray-5));
  --color-neutral-6: rgb(var(--gray-6));
  --color-neutral-7: rgb(var(--gray-7));
  --color-neutral-8: rgb(var(--gray-8));
  --color-neutral-9: rgb(var(--gray-9));
  --color-neutral-10: rgb(var(--gray-10));
  --color-text-1: var(--color-neutral-10);
  --color-text-2: var(--color-neutral-8);
  --color-text-3: var(--color-neutral-6);
  --color-text-4: var(--color-neutral-4);
  --color-fill-1: var(--color-neutral-1);
  --color-fill-2: #fafafa;
  --color-fill-3: var(--color-neutral-3);
  --color-fill-4: var(--color-neutral-4);
  --color-border-1: var(--color-neutral-2);
  --color-border-2: var(--color-neutral-3);
  --color-border-3: var(--color-neutral-4);
  --color-border-4: var(--color-neutral-6);
  --color-primary-light-1: rgb(var(--primary-1));
  --color-primary-light-2: rgb(var(--primary-2));
  --color-primary-light-3: rgb(var(--primary-3));
  --color-primary-light-4: rgb(var(--primary-4));
  --color-secondary: var(--color-neutral-2);
  --color-secondary-hover: var(--color-neutral-3);
  --color-secondary-active: var(--color-neutral-4);
  --color-secondary-disabled: var(--color-neutral-1);
  --color-danger-light-1: rgb(var(--danger-1));
  --color-danger-light-2: rgb(var(--danger-2));
  --color-danger-light-3: rgb(var(--danger-3));
  --color-danger-light-4: rgb(var(--danger-4));
  --color-success-light-1: rgb(var(--success-1));
  --color-success-light-2: rgb(var(--success-2));
  --color-success-light-3: rgb(var(--success-3));
  --color-success-light-4: rgb(var(--success-4));
  --color-warning-light-1: rgb(var(--warning-1));
  --color-warning-light-2: rgb(var(--warning-2));
  --color-warning-light-3: rgb(var(--warning-3));
  --color-warning-light-4: rgb(var(--warning-4));
  --color-link-light-1: rgb(var(--link-1));
  --color-link-light-2: rgb(var(--link-2));
  --color-link-light-3: rgb(var(--link-3));
  --color-link-light-4: rgb(var(--link-4));
  --border-radius-none: 0;
  --border-radius-small: 2px;
  --border-radius-medium: 4px;
  --border-radius-large: 8px;
  --border-radius-circle: 50%;
  --color-tooltip-bg: rgb(var(--gray-10));
  --color-spin-layer-bg: rgba(255,255,255,.6);
  --color-menu-dark-bg: #232324;
  --color-menu-light-bg: #ffffff;
  --color-menu-dark-hover: rgba(255,255,255,.04);
  --color-mask-bg: rgba(29,33,41,.6);
}